<template>
  <v-container>
    <h1>Account</h1>
    <account />

  </v-container>
</template>

<script>

  export default {
    components: {
      account: () => import('@/components/Account'),
      logout: () => import('@/components/Logout'),
      avatar: () => import('@/components/Avatar'),
    },
  }
</script>
